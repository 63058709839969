.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rbc-row-segment {
  font-size: 10px;
}
.rbc-event {
  padding: 0 !important;
  background: none;
  overflow: hidden;
}

.fc-h-event {
  /* background: none !important;
	border: 0 !important; */
}
.fc-v-event.yellow,
.fc-v-event.yellow .fc-event-main,
.fc-daygrid-event.yellow {
  background-color: rgb(255, 215, 0);
  color: black;
}

.fc-v-event.orange,
.fc-v-event.orange .fc-event-main,
.fc-daygrid-event.orange {
  background-color: rgb(210, 105, 30);
  color: white;
}

.fc .yellow .fc-list-event-dot {
  background-color: rgb(255, 215, 0);
  border: 5px solid rgb(255, 215, 0);
}
.fc .orange .fc-list-event-dot {
  background-color: rgb(210, 105, 30);
  border: 5px solid rgb(210, 105, 30);
}

.orange:hover {
  color: black;
}

.MuiIconButton-root {
  padding: 5px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.fc-h-event {
  border-color: transparent !important;
}

.fc .fc-daygrid-event {
  padding: 0;
}

.selectInput {
  width: 900px;
  position: relative;
}

.selectInput .wrappField {
  width: 100%;
  border: solid 1px silver;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
  border-radius: 4px;
  text-overflow: ellipsis;
}

.selectInput .wrappField:hover {
  border-color: black;
}

.selectInput .wrappFieldFocus {
  border: solid 1px #3f51b5;
  box-shadow:
    inset 1px 1px #3f51b5,
    inset -1px -1px #3f51b5;
}

.selectInput .wrappFieldFocus:hover {
  border-color: #3f51b5;
}

.selectInput .field {
  width: 100%;
  padding: 8.5px 0 8.5px 14px;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
}

.selectInput .field-input {
  width: -webkit-fill-available;
  border: none;
  height: 100%;
  outline: none;
  font-size: 16px;
}

.selectInput .field-icon {
  color: rgba(0, 0, 0, 0.54);
  width: 35px;
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
}

.selectInput .field-icon-focus svg {
  transform: rotate(180deg);
}

.selectInput .selectInput-title {
  top: 9px;
  left: 8px;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 6px;
  position: absolute;
  font-size: 16px;
  background: white;
  transition: all 200ms ease-out;
}

.selectInput .placeholderFocus {
  color: #3f51b5;
  transform: translate(-10%, -80%) scale(0.75);
}

.selectInput .placeholderInit {
  transform: translate(-10%, -80%) scale(0.75);
}

.selectInput .field-select {
  width: 100%;
  opacity: 1;
  z-index: 10;
  overflow: auto;
  position: absolute;
  min-width: 100px;
  transform: none;
  background: white;
  box-shadow:
    0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
  max-height: 300px;
  transition:
    opacity 303ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 202ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: 200px 0px;
}

.selectInput .wrapHint {
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  max-width: 300px;
}

.selectInput .wrapLink {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  max-width: 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rubrics .root {
  display: flex;
  margin-top: 5px;
}

.rubrics .wrappBody {
  display: flex;
  border-radius: 4px;
  border: solid 1px #3f51b5;
  margin-top: 5px;
}
.rubrics .expend {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  min-width: 30px;
}
.rubrics .item {
  min-width: 37px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  color: #3f51b5;
}
.rubrics .title {
  display: flex;
  align-items: center;
  color: black;
  min-width: 520px;
  max-width: 520px;
  overflow-wrap: anywhere;
}
.rubrics .children {
  padding-left: 20px;
}

.boxModal-inner {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 600px;
  position: relative;
}
.boxModal-inner ul {
  margin: 0;
  padding: 0;
}
.boxModal-inner ul li {
  list-style: none;
}
.boxError {
  position: absolute;
  top: 280px;
  right: 50%;
  transform: translatex(50%);
  color: red;
}
.boxBody {
  padding: 10px 20px;
}
.boxHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.boxFooter {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f0f0;
  margin-top: 30px;
}
@media screen and (max-height: 800px) {
  .boxBody-content {
    max-height: 500px;
    overflow: auto;
  }
}
@media screen and (max-height: 700px) {
  .boxBody-content {
    max-height: 300px;
    overflow: auto;
  }
}
@media screen and (max-height: 490px) {
  .boxBody-content {
    max-height: 150px;
    overflow: auto;
  }
}
.boxBody-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.boxBody-row > * {
  margin-right: 30px;
}
.boxBody-row-item {
  display: block;
  min-width: 200px;
  height: 40px;
}
.boxBody-row-item .MuiSelect-selectMenu {
  font-size: 16px !important;
}
.boxBody-row-item > span {
  display: block;
  font-size: 14px;
}
.boxBody-row-item > div {
  width: 100%;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px 0 7px;
}
.textField {
  margin-top: 20px !important;
  width: 900px;
}
.searchField {
  margin-top: 10px !important;
  margin-right: 10px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 900px;
  align-items: center;
}
.headerItem {
  margin-right: 30px;
}
.wrappPhoto {
  max-width: 300px;
}
.photo {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  max-width: 75px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropZone {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  border: dashed 1px #4caf50;
  padding: 50px 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  background-color: #f6f8f6;
  z-index: 30;
}
.dropZone:hover {
  border: dashed 1px #28772b;
}

.listFile {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.itemFile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.errMesg {
  position: absolute;
  top: 57px;
  color: red;
  z-index: 1000;
  font-size: 15px;
}
.meaning {
  max-height: calc(100vh - 189px);
  overflow: auto;
  width: 200px;
  margin-right: 20px;
  list-style-type: none;
  padding: 0;
}
.itemIi {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: grey;
  padding: 2px 0;
  cursor: pointer;
}
.itemIi:hover {
  color: #3f51b5;
}
